/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/widgets";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/accordions";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/tooltips";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/popovers";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/bootstrap-alerts";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/portfolio";
@import "../assets/styles/components/pricing-table";
@import "../assets/styles/components/email/mail-list-container";
@import "../assets/styles/components/email/mail-sidebar";
@import "../assets/styles/components/email/message-content";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/tickets";
@import "../assets/styles/components/project-list";
@import "../assets/styles/components/product-tile";
@import "../assets/styles/components/user-listing";
@import "../assets/styles/components/landing";
@import "../assets/styles/components/chats";
@import "../assets/styles/components/kanban";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/contex-menu";
@import "../assets/styles/components/plugin-overrides/no-ui-slider";
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/rating";
@import "../assets/styles/components/plugin-overrides/date-picker";
@import "../assets/styles/components/plugin-overrides/wizard";
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/sweet-alert";
@import "../assets/styles/components/plugin-overrides/full-calendar";
@import "../assets/styles/components/plugin-overrides/react-photo-gallery";
@import "../assets/styles/components/plugin-overrides/react-tag-autocomplete";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";

// * {
//   font-family: "Rethink Sans", sans-serif !important;
// }
.text_primary {
  color: #990d0e !important;
}
.btn {
  padding: 0.75rem 2.5rem !important;
  line-height: normal !important;
}
.custom_date {
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  background: hsl(240deg 15.79% 96.27%);
  &.select_csv {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
          display: none;
      }
      span {
          color: #a4a4c0;
      }
  }
  span {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0px;
      color: #24242d;
  }
}
button {
  &.btn-primary {
    background-color: #990d0e !important;
    border-color: #990d0e !important;
    &:hover {
      background-color: #8a0b0b !important;
      border-color: #8a0b0b !important;
    }
  }
  &.btn-info {
    background-color: #4942e4 !important;
    border-color: #4942e4 !important;
    &:hover {
      background-color: #473fd9 !important;
      border-color: #473fd9 !important;
    }
  }
}
div {
  &.card {
    border: none;
  }
  &.rdt_TableHeadRow {
    font-weight: 600;
    font-size: 13px;
  }
  &.rdt_TableRow:nth-of-type(odd) {
    background-color: #fdfdfd;
  }
  &.jBfjuD {
    font-size: 14px;
  }
}
span {
  &.edit_icon_btn {
    background-color: #ffffff;
    font-size: 18px;
    color: #4942e4;
    margin-right: 1rem;
    cursor: pointer;
    &:hover {
      color: #2d288b;
    }
  }
}
label {
  &.badge {
    margin-bottom: 0;
    font-size: 85%;
    font-weight: 500;
    &.badge-success {
      background-color: #1bcfb4;
    }
    &.badge-danger {
      background-color: #fe7c96;
    }
  }
}
.product-img-upload {
  padding: 4px;
  border: 2px dashed #ddd;
  border-radius: 12px;
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  & > div {
    position: relative;
    min-height: 200px;
    height: 100%;
    img {
      inset: 0;
      object-fit: scale-down;
    }
  }
}

.upload-new-product-img {
  position: absolute;
  opacity: 0;
  height: 100%;
  z-index: 2;
  inset: 0;
  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.delete_icon_btn {
  font-size: 20px;
}

.btn-light-input {
  position: relative;
  width: 100%;
  max-width: 350px;
}
.btn-light-input input {
  background-color: #fff;
  padding: 0.875rem 1rem 0.875rem 2.5rem;
  border: 1px solid #ffffff;
  color: #212529;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  width: 100%;
}
.btn-light-input input::placeholder {
  color: #bbb;
}
.btn-light-input input:hover,
.btn-light-input input:focus {
  color: #212529;
  border-color: #dae0e5;
}
.btn-light-input i {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
}

.toggle {
  position: relative;
  display: block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  font-size: 10px;
  display: block;
  background: #e6e6e6;
  border-radius: 8px;
  -webkit-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(9, 9, 255, 0.5);
  border-radius: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.toggle_switch:checked + .toggle:before {
  background: rgba(9, 9, 255, 0.15);
}

.toggle_switch:checked + .toggle span {
  background: #0909ff;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
    background 0.15s ease;
  -o-transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
    background 0.15s ease;
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  -webkit-box-shadow: 0 3px 8px rgba(9, 9, 255, 0.2);
  box-shadow: 0 3px 8px rgba(9, 9, 255, 0.2);
}

.toggle_switch:checked + .toggle span:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.subject_list_icon {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  padding: 4px;
  object-fit: contain;
}
.subject_details_img {
  width: 100%;
  max-width: 100px;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url(../assets/images/btn_close.svg);
  background-position: center;
  background-size: 1em;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.dashboard-card {
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}
.dashboard-card.policies {
  background-color: #eaf1fe;
}
.dashboard-card .dashboard-card-name .dash-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
.dashboard-card .dashboard-card-name .dash-number {
  font-size: 24px;
  font-weight: 500;
}
.dashboard-card.total-renewal {
  background-color: #fef8ed;
}
.dashboard-card.premium {
  background-color: #edf6ed;
}
.dashboard-card.payout {
  background-color: #ce232710;
}

.radial-chart-non-certify-info *,
.radial-chart-certify-info * {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
}
.radial-chart-non-certify-info h6,
.radial-chart-certify-info h6 {
  font-size: 14px;
}
.radial-chart-non-certify-info h4 {
  font-size: 24px;
  color: #f5b544;
}
.radial-chart-certify-info h4 {
  font-size: 24px;
  color: #2772f0;
}
.radial-chart-non-certify-info p,
.radial-chart-certify-info p {
  font-size: 14px;
  color: #091b3d50;
}
.radial-chart-non-certify-info .profit-signal,
.radial-chart-certify-info .profit-signal {
  font-size: 14px;
  color: #4b9f47;
}
.radial-chart-non-certify-info .loss-signal,
.radial-chart-certify-info .loss-signal {
  font-size: 14px;
  color: #e6521f;
}
.radial_chart svg {
  width: unset !important;
  height: unset !important;
}
.total-premium-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}
.total-premium-amount {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  color: #091b3d;
}
.total-premium-amount span {
  font-size: 24px;
}
.total-premium-amount p {
  font-size: 14px;
  margin-top: 10px;
}
.total-premium-percent span {
  font-weight: 700;
  font-size: 14px;
  color: #4b9f47;
}
.CircularProgressbar {
  width: 100%;
}
.average-product-sale-progess {
  font-family: "Cera Pro", sans-serif;
}
.average-product-sale-progess > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-sale-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // max-width: calc(125px - 24px);
}
.product-sale-progress h5 {
  font-weight: 700;
  font-size: 24px;
  color: #091b3d;
  text-align: center;
  margin: 24px 0 10px;
}
.product-sale-progress p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #091b3d50;
  margin-bottom: 1rem;
}
.average-product-sale-progess .sale-desc {
  padding: 24px;
  border-radius: 24px;
  background: #f5b54410;
  font-weight: 700;
  font-size: 14px;
  color: #091b3d;
}
.sidebar .nav .nav-item + .nav .nav-item .nav-link i {
  font-size: 1.125rem;
  line-height: 1;
  margin-left: auto;
  color: rgba(153, 174, 161, 0.9607843137);
}
.setting_checkbox {
  opacity: 100% !important;
  position: unset !important;
  margin: 2px 8px 0 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa !important;
}
.form-control,
.swal2-modal .swal2-content .swal2-input {
  border: 1px solid #e0e0e0 !important;
}
select.form-control,
.swal2-modal .swal2-content select.swal2-input {
  outline: 0 !important;
  color: #495057 !important;
}
select.form-control::placeholder,
.swal2-modal .swal2-content select.swal2-input::placeholder {
  outline: 0 !important;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url(../assets/images/btn_close.svg);
  background-position: center;
  background-size: 1em;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.pastel_red {
  background-color: #990d0e !important;
}
.pastel_violet {
  background-color: #484848 !important;
}
.pastel_blue {
  background-color: #dbae58 !important;
}
.pastel_yellow {
  background-color: #dadada !important;
  color: #000 !important;
}
.piechart_list {
  list-style: none;
  padding: 0;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
}
.piechart_list li {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.piechart_list li > div {
  width: 18px;
  height: 10px;
}
.piechart_list li > p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
#tooltip {
  font-size: 12px;
  font-weight: bolder;
  border-radius: 50%;
  border: 1px solid #777;
  color: #777;
  width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide arrow in number input for Firefox */
input[type="number"] {
  appearance: textfield;
    -moz-appearance: textfield;
}
.dFsSgh {
  min-width: 155px !important;
}