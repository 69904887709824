@mixin social-button($color) {
  background: $color;
  color: $white;

  &:hover,
  &:focus {
    background: darken($color, 10%);
    color: $white;
  }
  &.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: lighten($color, 10%);
    i {
      background: $color;
      padding: 0.75rem;
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
}
@mixin social-outline-button($color) {
  border: 1px solid $color;
  color: $color;
  &:hover {
    background: $color;
    color: $white;
  }
}
@mixin button-inverse-variant($color, $color-hover: $white) {
  background-color: rgba($color, 0.2);
  background-image: none;
  border-color: rgba($color, 0);
  &:not(.btn-inverse-light) {
    color: $color;
  }
  @include hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &.focus,
  &:focus {
    box-shadow: 0 0 0 3px rgba($color, 0.5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &.active,
  &:active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}
@mixin button-gradient-variant($value) {
  background: $value;
  border: 0;
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: $value;
  }
  &:hover {
    opacity: 0.8;
  }
  &:not(.btn-gradient-light) {
    color: $white;
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
  @include transition(opacity 0.3s ease);
}

.view-more-btn {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #990D0E;
  &:hover {
    color: #363636;
    text-decoration: none;
    svg {
      opacity: 1;
      -webkit-transform: translate(3px);
      transform: translate(3px);
    }
  }
  svg {
    position: relative;
    height: 16px;
    width: 16px;
    opacity: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.mdi-delete-outline {
  color: #363636;
  &:hover {
    opacity: 0.7;
  }
}